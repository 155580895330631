import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    userInfo: null,
    powerList: [],
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_USER_INFO_STATE(state, val) {
      state.userInfo = val
    },
    UPDATE_POWER_LIST_STATE(state, val) {
      state.powerList = val
    },
  },
  actions: {
    // (查詢)員工列表
    getUserList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/user', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一管理者
    getUserData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/user/${resolveData.user_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)員工大頭貼
    setUserImageUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/user/${resolveData.user_id}/avatar`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)使用者
    setUserCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/user', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)使用者
    setUserUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/user/${resolveData.user_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)使用者密碼
    setUserPasswordUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/user/${resolveData.user_id}/password`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)使用者權限
    setUserPowerUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/user/${resolveData.user_id}/power`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)使用者
    setUserDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/user/${resolveData.user_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
