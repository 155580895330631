import { ref, watch } from '@vue/composition-api' // , computed
// import moment from 'moment'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useUserSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const stateOptions = [{
    label: '停用', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const ui = {
    stateList: [{
      false: '停用', true: '啟用',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    stateOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useUserList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '管理者', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次登入', key: 'last_login_ip', sortable: true, searchable: true, select: true,
    },
    // {
    //   label: '上次登入', key: 'last_login_at', sortable: true, searchable: true, select: true,
    // },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchState = ref(null)
  const blankUserData = {
    id: null,
    ssid: null,
    account: null,
    image: null,
    name: null,
    last_login_at: null,
    last_login_ip: null,
    // register_ip: null,
    // power: null,
    state: false,
    // content: null,
    created_at: null,
    updated_at: null,
  }

  const setUserDelete = (...arg) => store.dispatch('admin-user/setUserDelete', ...arg)
  const setUserCreate = (...arg) => store.dispatch('admin-user/setUserCreate', ...arg)
  const setUserUpdate = (...arg) => store.dispatch('admin-user/setUserUpdate', ...arg)
  const setUserPasswordUpdate = (...arg) => store.dispatch('admin-user/setUserPasswordUpdate', ...arg)

  const getUserListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value ? 1 : 0}`
    }

    // 整理timeRange
    // let resolveTimeRange = null
    // if (timeRange.value) {
    //   resolveTimeRange = timeRange.value.split(' 至 ')
    //   const [start, end] = resolveTimeRange
    //   if (end) {
    //     resolveTimeRange = `${start} to ${end}`
    //   } else resolveTimeRange = `${start}`
    // }

    store.dispatch('admin-user/getUserList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankUserData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchState,
    timeRange,
    blankUserData,
    refetchData,
    refetchTable,

    setUserDelete,
    setUserCreate,
    setUserUpdate,
    setUserPasswordUpdate,
    getUserListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useUserViewGeneral = () => {
  const {
    syncObject,
    getMetaListData,
  } = useUserSetting()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    isBusy,
    blankUserData,
  } = useUserList()

  const blnkPowerData = {
    id: null,
    name: null,
    route_path: null,
    group: null,
    state: false,
  }

  const blankUserInfoData = {
    ...blankUserData,
    power: [],
  }

  const generalData = ref(null)
  const isLoadingBusy = ref(false)
  const groupPowerOptions = ref([])

  const getPowerListData = (filter, callback) => {
    isLoadingBusy.value = true
    // store.dispatch('api/getMetaList', { key: 'power', queryParams: { noPagination: true, filters: resolveFilters } })
    getMetaListData({ ...filter })
      .then(response => {
        const { data } = response.data.data
        const powerOptions = data.map(item => {
          const resolve = {
            ...syncObject(blnkPowerData, item),
          }
          return resolve
        })
        // groupPowerOptions.value = Object.values(powerOptions.reduce((acc, item) => {
        //   if (!acc[item.group]) {
        //     acc[item.group] = { name: item.group, options: [] }
        //   }
        //   acc[item.group].options.push({
        //     ...item,
        //     value: false,
        //   })
        //   return acc
        // }, {}))
        store.commit('admin-user/UPDATE_POWER_LIST_STATE', powerOptions)
        isLoadingBusy.value = false
        callback()
      })

    // setTimeout(() => {
    //   const powerOptions = [
    //     {
    //       id: 1,
    //       name: '用戶列表',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user',
    //     }, {
    //       id: 2,
    //       name: '用戶資訊',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user.id',
    //     }, {
    //       id: 3,
    //       name: '新增用戶',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user.create',
    //     }, {
    //       id: 28,
    //       name: '權限列表',
    //       state: true,
    //       group: '權限',
    //       route_path: 'API.Admin.power',
    //     }, {
    //       id: 27,
    //       name: '權限列表(全部)',
    //       state: true,
    //       group: '列表',
    //       route_path: 'API.Admin.meta.power',
    //     }, {
    //       id: 26,
    //       name: '重設客戶密碼',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.password',
    //     }, {
    //       id: 25,
    //       name: '移除客戶大頭照',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.image.clear',
    //     }, {
    //       id: 24,
    //       name: '上傳客戶大頭照',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.image',
    //     }, {
    //       id: 23,
    //       name: '移除客戶驗證圖片',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.kyc.clear',
    //     }, {
    //       id: 22,
    //       name: '上傳客戶驗證圖片',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.kyc',
    //     }, {
    //       id: 21,
    //       name: '更新客戶',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.update',
    //     }, {
    //       id: 20,
    //       name: '新增客戶',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.create',
    //     }, {
    //       id: 19,
    //       name: '客戶資訊',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.show',
    //     }, {
    //       id: 18,
    //       name: '客戶列表',
    //       state: true,
    //       group: '客戶',
    //       route_path: 'API.Admin.customer.index',
    //     }, {
    //       id: 17,
    //       name: '移除分站橫幅',
    //       state: true,
    //       group: '分站',
    //       route_path: 'API.Admin.branch.banner.clear',
    //     }, {
    //       id: 16,
    //       name: '上傳分站橫幅',
    //       state: true,
    //       group: '分站',
    //       route_path: 'API.Admin.branch.banner',
    //     }, {
    //       id: 15,
    //       name: '移除分站圖標',
    //       state: true,
    //       group: '分站',
    //       route_path: 'API.Admin.branch.icon.clear',
    //     }, {
    //       id: 14,
    //       name: '上傳分站圖標',
    //       state: true,
    //       group: '分站',
    //       route_path: 'API.Admin.branch.icon',
    //     }, {
    //       id: 12,
    //       name: '清除管理員圖片',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user.image.clear',
    //     }, {
    //       id: 11,
    //       name: '上傳用戶圖片',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user.image',
    //     }, {
    //       id: 9,
    //       name: '更新分站',
    //       state: true,
    //       group: '分站',
    //       route_path: 'API.Admin.branch.update',
    //     }, {
    //       id: 8,
    //       name: '編輯用戶權限',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user.power',
    //     }, {
    //       id: 7,
    //       name: '新增分站',
    //       state: true,
    //       group: '分站',
    //       route_path: 'API.Admin.branch.create',
    //     }, {
    //       id: 6,
    //       name: '分站列表',
    //       state: true,
    //       group: '分站',
    //       route_path: 'API.Admin.branch',
    //     }, {
    //       id: 4,
    //       name: '更新用戶',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user.update',
    //     }, {
    //       id: 5,
    //       name: '新增用戶',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user.create',
    //     }, {
    //       id: 10,
    //       name: '用戶資訊',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user.id',
    //     }, {
    //       id: 13,
    //       name: '用戶列表',
    //       state: true,
    //       group: '用戶',
    //       route_path: 'API.Admin.user',
    //     },
    //   ]
    // }, 2000)

    // const dataDict = powerOptions.reduce((acc, item) => {
    //   acc[item.id] = item
    //   return acc
    // }, {})
  }

  // getPowerListData({ key: 'power' })

  const getUserInfoData = (...args) => {
    store.commit('admin-user/UPDATE_BUSY_LOADING_STATE', true)
    store.dispatch('admin-user/getUserData', ...args)
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankUserInfoData, data),
          // information: syncObject(blankInformationData, data.information ? data.information : {}),
        }
        if (data.power) resolveData.power = data.power.split('.').map(item => parseInt(item, 10))
        store.commit('admin-user/UPDATE_USER_INFO_STATE', resolveData)
        store.commit('admin-user/UPDATE_BUSY_LOADING_STATE', false)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const setUserUpdate = (...args) => store.dispatch('admin-user/setUserUpdate', ...args)
  const setUserPowerUpdate = (...arg) => store.dispatch('admin-user/setUserPowerUpdate', ...arg)
  const setUserImageUpload = (...args) => store.dispatch('admin-user/setUserImageUpload', ...args)

  return {
    getPowerListData,
    getUserInfoData,
    setUserUpdate,
    setUserPowerUpdate,
    setUserImageUpload,
    isBusy,
    isLoadingBusy,
    blankUserInfoData,
    generalData,
    groupPowerOptions,
  }
}
