<template>
  <div>
    <b-skeleton-wrapper :loading="adminUserState.isBusyLoading">
      <template #loading>
        <b-card>
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-avatar
                  rounded
                  size="9rem"
                />
              </b-link>
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <div class="p-2">
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
              </div>
            </b-media-body>
          </b-media>
        </b-card>
      </template>
    </b-skeleton-wrapper>

    <div v-if="!adminUserState.isBusyLoading">
      <b-card>
        <b-row>
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column mb-1"
          >
            <div class="d-flex justify-content-start">
              <div class="profile-image">
                <b-avatar
                  ref="previewEl"
                  size="9rem"
                  rounded
                  :src="adminUserState.userInfo.image"
                  variant="light-secondary"
                />

                <div
                  class="profile-image-mask animate__animated animate__fadeIn"
                >
                  <div
                    class="profile-icon profile-image-edit"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/edit-w.svg"
                      width="30"
                      height="30"
                    />
                  </div>
                  <div
                    v-if="adminUserState.userInfo.image"
                    class="profile-icon profile-image-reset"
                    @click="removeImage"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/refresh-w.svg"
                      width="16"
                      height="16"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h2 class="mb-0">
                    {{ adminUserState.userInfo.name }}
                  </h2>
                </div>
                <div class="d-flex flex-wrap">
                  <!-- 上傳按鈕 -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    上傳
                  </b-button>

                  <!-- 重設按鈕 -->
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="removeImage"
                  >
                    重設
                  </b-button>
                </div>

                <b-card-text>
                  只允許副檔名為JPG, PNG的檔案.
                </b-card-text>
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            xl="6"
          >
            <b-row>
              <b-col
                cols="12"
                xl="12"
                lg="6"
              >
                <b-form-group
                  label-cols="4"
                  label="帳號"
                  class="m-0"
                >
                  <div class="mt-50">
                    {{ adminUserState.userInfo.account }}
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="上次登入"
                  class="m-0"
                >
                  <div class="mt-50">
                    {{ adminUserState.userInfo.last_login_ip ? adminUserState.userInfo.last_login_ip : '---' }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                xl="12"
                lg="6"
              >
                <b-form-group
                  label-cols="4"
                  label="創建時間"
                  class="m-0"
                >
                  <div class="mt-50">
                    {{ adminUserState.userInfo.created_at ? moment(adminUserState.userInfo.created_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="上次更新"
                  class="m-0"
                >
                  <div class="mt-50">
                    {{ adminUserState.userInfo.updated_at ? moment(adminUserState.userInfo.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <!-- 上傳圖片 -->
      <b-form-file
        ref="refInputEl"
        accept=".jpg, .png"
        :hidden="true"
        plain
        @change="loadImage($event)"
      />

      <!-- 圖片裁剪 -->
      <b-modal
        id="modal"
        centered
        :no-close-on-backdrop="close"
        :no-close-on-esc="close"
      >

        <template #modal-header>
          <h4>圖片裁剪</h4>
        </template>

        <div class="upload-example">
          <cropper
            ref="cropper"
            :src="image"
            :stencil-props="{
              aspectRatio: 1/1,
            }"
            :canvas="{
              minHeight: 0,
              minWidth: 0,
              maxHeight: 300,
              maxWidth: 300,
            }"
            :auto-zoom="false"
            :debounce="false"
            @change="onChange"
          />

          <!-- <preview
            :width="200"
            :height="200"
            :image="result.image"
            :coordinates="result.coordinates"
          /> -->
        </div>

        <template #modal-footer>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>

          <b-button
            size="sm"
            variant="primary"
            @click="uploadImage"
          >
            上傳
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
// API
import moment from 'moment'
// UI
import {
  BSkeletonWrapper, BSkeleton, BCard, BMedia, BMediaAside, BMediaBody, BAvatar,
  BButton, BLink, BCardText, BRow, BCol, BFormGroup, BFormFile, BModal, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { useUserViewGeneral } from '../useUser'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import store from '@/store'

export default {
  components: {
    BCard,
    BLink,
    BAvatar,
    BButton,
    BMedia,
    BRow,
    BCol,
    BImg,
    BFormGroup,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BModal,
    BSkeleton,
    BSkeletonWrapper,

    Cropper,
  },
  directives: {
    Ripple,
  },
  mixins: [useSwalToast],
  data() {
    return {
      close: true,
      image: null,
      result: {
        coordinates: null,
        image: null,
      },
    }
  },
  computed: {
    adminUserState() {
      return this.$store.state['admin-user']
    },
  },
  methods: {
    moment,
    // 圖片預覽
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      }
    },

    // 圖片裁剪
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.image = canvas.toDataURL()
    },

    // 載入圖片
    loadImage(event) {
      const dirNameSplit = event.target.files[0].name.split('.')
      const type = dirNameSplit[dirNameSplit.length - 1]
      if (type !== 'jpg' && type !== 'png' && type !== 'PNG' && type !== 'JPG') return
      this.$bvModal.show('modal')
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => { this.image = e.target.result }
        reader.readAsDataURL(input.files[0])
      }
    },

    // (上傳)圖片
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const formData = new FormData()
        canvas.toBlob(blob => {
          formData.append('data', blob)
          this.setUserImageUpload({
            user_id: this.adminUserState.userInfo.id,
            data: formData,
          })
            .then(response => {
              const { image } = response.data.data
              const userData = JSON.parse(localStorage.getItem('userData'))
              if (userData && userData.id === this.adminUserState.userInfo.id) {
                userData.image = image
                localStorage.setItem('userData', JSON.stringify(userData))
                store.commit('app/UPDATE_USERDATA_STATE', userData)
              }
              const resolveUserInfo = {
                ...this.adminUserState.userInfo,
              }
              resolveUserInfo.image = image
              this.$store.commit('admin-user/UPDATE_USER_INFO_STATE', resolveUserInfo)
              this.useAlertToast(response.data.success, response.data.message)
            })
            .catch(error => {
              this.useSwalAlertCenter(false, '上傳失敗!', error.response.data.message)
            })
        })
      }
      this.$refs.refInputEl.reset()
      this.image = null
      this.$bvModal.hide('modal')
      this.result.coordinates = null
    },

    // (刪除)圖片
    removeImage() {
      this.setUserUpdate({
        user_id: this.adminUserState.userInfo.id,
        data: {
          image: null,
        },
      })
        .then(response => {
          const userData = JSON.parse(localStorage.getItem('userData'))
          if (userData && userData.id === this.adminUserState.userInfo.id) {
            userData.image = null
            localStorage.setItem('userData', JSON.stringify(userData))
            store.commit('app/UPDATE_USERDATA_STATE', userData)
          }
          const resolveUserInfo = {
            ...this.adminUserState.userInfo,
          }
          resolveUserInfo.image = null
          this.$store.commit('admin-user/UPDATE_USER_INFO_STATE', resolveUserInfo)
          this.useAlertToast(response.data.success, response.data.message)
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
        })
    },

    // 取消上傳
    cancel() {
      this.$bvModal.hide('modal')
      this.$refs.refInputEl.reset()
      this.result.coordinates = null
      this.image = null
    },
  },
  setup() {
    const {
      ui,
      setUserUpdate,
      setUserImageUpload,
    } = useUserViewGeneral()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      useAlertToast,
      useHttpCodeAlert,

      ui,
      setUserUpdate,
      setUserImageUpload,
    }
  },
}
</script>

<style lang="scss" scoped>
.socialicon {
  max-width: 28px;
  margin-right: 12px;
  margin-bottom: 5px;
}
.nosocial {
  opacity: 0.2;
}

.profile-image {
  position: relative;
  .profile-image-mask {
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000080;
    user-select: none;
    .profile-icon {
      cursor: pointer;
    }
    .profile-image-reset {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      // border: 1px #6f6f6fbd solid;
      background-color: #69bdf2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    .profile-image-mask {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
